import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { TableList, Loader } from '@ederzadravec/jade-ui';

import { Box, Text, Space } from 'components';
import { useService } from 'service';
import { dbToDate } from 'utils/date';
import { getQuery } from 'utils/url';

const Container = styled(Box)`
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const ChatHistory = () => {
  const location = useLocation();
  const query = getQuery(location.search);

  const [viewersChats, { loading: loadingViewersChats }] = useService(
    'get',
    '/dashboard/viewers-chats',
    { room: query.room },
    true,
    [query.room]
  );

  const configList = [
    {
      name: 'Mensagem',
      dataPath: 'content',
      size: {
        md: 4,
      },
    },

    {
      name: 'Usuário',
      dataPath: data => (data.viewer ? data.viewer.name : data.user.name),
      size: {
        md: 3,
      },
    },

    {
      name: 'Data e Hora',
      dataPath: data => dbToDate(data.createdAt, 'dd/MM/yyyy hh:mm:ss'),
      size: {
        md: 3,
      },
    },

    {
      name: 'Destaque',
      dataPath: data => (data.isDetach ? 'sim' : 'não'),
      size: {
        md: 2,
      },
    },
  ];

  return (
    <Container>
      <Text type="h" bold color="primary">
        Histórico de Chat
      </Text>

      <Space mt={2} />
      <Loader show={loadingViewersChats}>
        <TableList config={configList} data={viewersChats?.success?.chats?.data || []} />
      </Loader>
    </Container>
  );
};
