import React from 'react';
import { Loader, Space } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { Box } from 'components';

import { SelectDayRoom } from './SelectDayRoom';
import { GroupRooms } from './GroupRooms';
import { useService } from 'service';
import { getQuery } from 'utils/url';
import { dbToDate, newDate } from 'utils/date';

const groupDates = dates => {
  const newList = dates.reduce((acc, value) => {
    const date = dbToDate(value.date);
    return { ...acc, [date]: true };
  }, {});

  return Object.keys(newList);
};

const getRoomsOfDay = (date, list) => {
  return list.filter(item => dbToDate(item.date) === date);
};

const ListRooms = () => {
  const [initDate, setInitDate] = React.useState(newDate());
  const [nextRooms, { loading: loadingNext }] = useService('get', 'app/room/next?limit=200');

  const urlQuery = getQuery(location.search);
  const selectedDate = urlQuery.date || initDate;

  React.useEffect(() => {
    if (urlQuery.date) setInitDate(null);
  }, [urlQuery]);

  const datesAvailable = React.useMemo(() => {
    return groupDates(nextRooms?.success?.rooms || []);
  }, [nextRooms]);

  const listRooms = React.useMemo(() => {
    if (!datesAvailable.length) return [];

    const valida = datesAvailable.find(item => item === selectedDate);

    return valida
      ? getRoomsOfDay(selectedDate, nextRooms?.success?.rooms || [])
      : nextRooms?.success?.rooms || [];
  }, [datesAvailable, urlQuery]);

  return (
    <>
      <SelectDayRoom
        data={datesAvailable}
        selectedDate={selectedDate}
        clearSelectDate={() => setInitDate(null)}
      />

      <Space top={4} />

      <Loader direction="column" loading={loadingNext}>
        {listRooms.map(date => (
          <GroupRooms date={date} />
        ))}
      </Loader>
    </>
  );
};

export const Rooms = () => {
  const BASE_URL = '/app';

  return (
    <Switch>
      <Route path={[`${BASE_URL}`, `${BASE_URL}/room/:room/modal`]} component={ListRooms} />
    </Switch>
  );
};
