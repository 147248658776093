import React, { useContext } from 'react';

import { Text, Space } from 'components';
import { Room } from 'contexts';
import { dbToDate, newDate, newDbDate } from 'utils/date';

import { ContextLanguage } from 'contexts';

import * as S from './style';

export const FeaturedMessageOfChat = ({ idRoom }) => {
  const { detach } = React.useContext(Room.Context);

  if (!detach?.content) return null;

  const cxtLang = useContext(ContextLanguage);

  return (
    <S.Container>
      <S.Content>
        <S.ContentInfo>
          <Text size="15"  type="h" color="dark">
            {detach.user && 'TOTVS'}
            {detach.viewer && detach.viewer.name}
          </Text>

          <Space ml={2} />

          <Text size="16" type="p" color="dark">
            {dbToDate(detach.createdAt, 'HH:mm')}
          </Text>
        </S.ContentInfo>

        <S.Message
          type="p"
          size="15"
          color="dark"
          dangerouslySetInnerHTML={{ __html: detach.content }}
        />
      </S.Content>
    </S.Container>
  );
};
