import React, { useState, useContext } from 'react';
import { FaChevronRight, FaChevronLeft, FaRedo } from 'react-icons/fa';

import { Loader } from '@ederzadravec/jade-ui';

import { useService } from 'service';
import { ContextLanguage } from 'contexts';

import {
  Container,
  Header,
  Body,
  ContainerList,
  ContainerImage,
  Image,
  ListImages,
  ButtonControlPage,
} from './style';

import { Text } from '../Text';
import { Space } from '../Space/Space';
import { Box } from '../Box/Box';

const ImageItem = ({ src }) => {
  return (
    <ContainerImage>
      <Image src={src} />
    </ContainerImage>
  );
};

const GridInstagram = ({ data, loading }) => {
  return (
    <ContainerList>
      <Loader show={loading}>
        <ListImages>
          {data.map(post => (
            <ImageItem key={post.id} src={`data:image/png;base64, ${post.path}`} />
          ))}
        </ListImages>
      </Loader>
    </ContainerList>
  );
};

export const ListInstagram = () => {
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState('');
  const cxtLang = useContext(ContextLanguage);
  const limit = 10;

  const [dataInstagram, { loading }] = useService(
    'get',
    `/instagram?limit=${limit}`,
    { page },
    true,
    [refresh, page]
  );

  const data = dataInstagram?.success?.posts?.data || [];
  const totalPages = Math.ceil(
    Number(dataInstagram?.success?.posts?.total?.total || limit) / Number(limit)
  );

  console.log('eee', page);

  //return <></>;

  return (
    <Container>
      <Box full>
        <Header>
          <div style={{ maxWidth: '70%' }}>
            <Text type="h" finality="title" color="black">
            COMPARTILHE SUA PARTICIPAÇÃO NA CONVENÇÃO!
            </Text>

            <Space mt={0.3} />

            {cxtLang.lang === 'pt' && (
              <Text size="18" color="black">
                Poste sua foto no seu Instagram utilizando

                <Text size="18" color="secondary">
                  {' '}
                  #SOMOSAR
{' '}
                </Text>
                que ela será divulgada nesta área. Atenção: o seu perfil do Instagram tem que ser público para a foto aparecer aqui.
              </Text>
            )}
            {cxtLang.lang === 'en' && (
              <Text size="18" color="black">
                Utilize a
                <Text size="18" color="secondary">
                  {' '}
                  #CONVENCAOTOTVS2023{' '}
                </Text>
                no Instagram para ver sua foto aqui no nosso mural
              </Text>
            )}
            {cxtLang.lang === 'es' && (
              <Text size="18" color="black">
                Publica tu foto en Instagram usando
                <Text size="18" color="secondary">
                  {' '}
                  #SOMOSAR{' '}
                </Text>
                y se publicará en esta zona. Nota: tu perfil de Instagram debe ser público para que la foto aparezca aquí.
              </Text>
            )}
          </div>

          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {totalPages > 1 && (
              <Text size="18" color="black">
                {cxtLang.lang === 'pt' && `Página ${page} de ${totalPages}`}
                {cxtLang.lang === 'en' && `Page ${page} of ${totalPages}`}
                {cxtLang.lang === 'es' && `Página ${page} de ${totalPages}`}
              </Text>
            )}

            {page > 1 && (
              <ButtonControlPage onClick={() => setPage(page - 1)}>
                <FaChevronLeft />
              </ButtonControlPage>
            )}

            {totalPages > page ? (
              <ButtonControlPage onClick={() => setPage(page + 1)}>
                <FaChevronRight />
              </ButtonControlPage>
            ) : (
              <ButtonControlPage
                noSize
                onClick={() => {
                  setRefresh(String(new Date()));
                  setPage(1);
                }}>
                {cxtLang.lang === 'pt' && `Atualizar`}
                {cxtLang.lang === 'en' && `Update`}
                {cxtLang.lang === 'es' && `Actualizar`}
                {/* <FaRedo /> */}
              </ButtonControlPage>
            )}
          </div>
        </Header>

        <Body>
          <GridInstagram data={data} loading={loading} />
        </Body>
      </Box>
    </Container>
  );
};
