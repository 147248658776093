import React, { useContext } from 'react';
import styled from 'styled-components';
import { Grid } from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 14px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const ImageMobile = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    border-radius: 14px;
    height: auto;
    width: 100%;
    cursor: pointer;
  }
`;

const ImageDesk = styled.img`
  display: block;
  border-radius: 14px;
  height: auto;
  width: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContainerCallAction = ({ ImageBgRooms }) => {
  const ContainerCall = styled.div`
    background-image: url(${ImageBgRooms});
    border-radius: 14px;
    margin-bottom: 24px;
    padding: 32px 24px;
    width: 100%;
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    @media (max-width: 768px) {
    }
  `;

  const TitleCall = styled(Text)`
    width: 100%;
    font-weight: 700;
  `;

  const ButtonCall = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
    height: 56px;
    font-size: 18px;
    border-radius: 10px;
    padding: 12px 20px;
    font-weight: bold;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    background: transparent;
    cursor: pointer;
  `;

  const cxtLang = useContext(ContextLanguage);

  return (
    <ContainerCall>
      <TitleCall type="h" bold size="24" color="primary" center>
        {cxtLang.lang === 'pt' && 'ASSISTA AOS VÍDEOS DA CONVENÇÃO DE VENDAS 2021'}
        {cxtLang.lang === 'en' && 'WATCH THE 2021 SALES CONVENTION VIDEOS'}
        {cxtLang.lang === 'es' && 'MIRA LOS VIDEOS DE LA CONVENCIÓN DE VENTAS 2021'}
      </TitleCall>

      <Space mt={2} />

      <ButtonCall href="https://drive.google.com/drive/folders/0ACuf4cPNthbwUk9PVA" target="_blank">
        {cxtLang.lang === 'pt' && 'ASSISTIR'}
        {cxtLang.lang === 'en' && 'WATCH'}
        {cxtLang.lang === 'es' && 'MIRA'}
      </ButtonCall>
    </ContainerCall>
  );
};

export const BannerSponsorMedium = () => {
  const [dataBanner, { loading }, fetchBanner] = useService('get', `/banner/medium`);

  if (dataBanner?.error) return;

  const banner = dataBanner?.success?.banner;

  const handleOnClick = route => {
    window.open(route, '_blank');
  };

  return (
    <Grid container>
      <Grid size={{ md: 12, xs: 12 }}>
        <Container>
          <ImageMobile
            src={banner?.imageMobile}
            onClick={() => (banner?.link === '#' ? '' : handleOnClick(banner?.link))}
          />
          <ImageDesk
            src={banner?.image}
            onClick={() => (banner?.link === '#' ? '' : handleOnClick(banner?.link))}
          />
        </Container>
      </Grid>
      {/* <Grid size={{ md: 3, xs: 12 }}>
        <ContainerCallAction ImageBgRooms={ImageBgRooms} />
      </Grid> */}
    </Grid>
  );
};
