import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Space } from '@ederzadravec/jade-ui';

import { dbToDate } from 'utils/date';

import { Text } from 'components';

import { ContextLanguage } from 'contexts';

import { Container, ContentTitle, ContentDescription, ContentActions } from './style';

export const CardRoom = ({ data }) => {
  const cxtLang = useContext(ContextLanguage);
  const history = useHistory();

  const handleToAgenda = () => {
    const link = `
    https://calendar.google.com/calendar/u/0/r/eventedit?text=${data.name[cxtLang.lang]}
    &dates=${dbToDate(data.openAt, "yyyyMMdd'T'HHmmss")}/${dbToDate(
      data.closeAt,
      "yyyyMMdd'T'HHmmss"
    )}
    &ctz=America/Sao_Paulo
    &details=${data.resumeDescription[cxtLang.lang]}
    &location=${process.env.FRONT_URI}app/room/${data._id}
    &pli=1
    &uid=
    &sf=true
    &output=xml
    `;

    const win = window.open(link, '', 'width=800,height=600');
    win.focus();
  };

  return (
    <Container>
      <ContentTitle>
        <Text type="h" size="16" bold={600} color="black" mobile={`margin-bottom: 16px;`}>
          {data.name[cxtLang.lang]}
        </Text>
      </ContentTitle>

      {/* <ContentDescription>
        <Text type="h" size="18" color="black" mobile={`margin-bottom: 16px;`}>
          {data.title[cxtLang.lang]}
        </Text>
      </ContentDescription> */}

      <ContentActions>
        {/* <Button color="secondary" onClick={() => handleToAgenda()}>
          {cxtLang.lang === 'pt' && 'Salvar na agenda'}
          {cxtLang.lang === 'en' && 'Save to calendar'}
          {cxtLang.lang === 'es' && 'Guardar en el calendario'}
        </Button> */}

        {/* <Button color="secondary" onClick={() => setIdModalShow(data)}>
          {cxtLang.lang === 'pt' && 'Ver programação'}
          {cxtLang.lang === 'en' && 'See schedule'}
          {cxtLang.lang === 'es' && 'Ver horario'}
        </Button> */}

        {data.isClosed === true && data.isOnline === false && (
          <Button
            disabled
            color="tertiary"
            style={{ maxWidth: '152px' }}
            onClick={() => history.push(`/app/room/${data._id}`)}>
            {cxtLang.lang === 'pt' && 'ENCERRADO'}
            {cxtLang.lang === 'en' && 'Closed'}
            {cxtLang.lang === 'es' && 'CERRADO'}
          </Button>
        )}

        {data.isOnline === false && data.isClosed === undefined && (
          <Button style={{ maxWidth: '152px' }} color="tertiary" disabled>
            {cxtLang.lang === 'pt' && 'EM BREVE'}
            {cxtLang.lang === 'en' && 'Coming soon'}
            {cxtLang.lang === 'es' && 'EN BREVE'}
          </Button>
        )}

        {data.isOnline === true && (
          <Button
            style={{ maxWidth: '152px' }}
            color="tertiary"
            onClick={() => history.push(`/app/room/${data._id}`)}>
            {cxtLang.lang === 'pt' && 'ASSISTIR'}
            {cxtLang.lang === 'en' && 'WATCH'}
            {cxtLang.lang === 'es' && 'VER'}
          </Button>
        )}
      </ContentActions>
    </Container>
  );
};
