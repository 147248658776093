import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { ptBR, es, enUS } from 'date-fns/locale';
import { Space } from '@ederzadravec/jade-ui';

import { dbToDate, dateToDb } from 'utils/date';
import { Text } from 'components';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  background-size: cover;
  border-radius: 14px;
  background-color: #ffffff;
  width: 100%;
  padding: 24px 24px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 24px 32px;
    flex-wrap: wrap;
  }
`;

const ContentDays = styled.div`
  display: flex;
  margin-right: 8px;

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    margin: unset;
    flex-wrap: wrap;
  }
`;

const ButtonDay = styled.button`
  margin: 0px 0px 0px 16px;
  padding: 16px 8px;
  font-weight: bold;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.white.main};
  padding: 10px 24px 10px 24px;
  border-radius: 4px;

  font-size: 16px;

  ${({ active, theme }) =>
    active
      ? css`
          // border: 2px solid ${theme.palette.black.main};
          border: 2px solid transparent};
          background-color: ${theme.palette.tertiary.main};
          `
      : css`
          border: 2px solid transparent};
          background-color: ${theme.palette.secondary.main};
        `}

  @media (max-width: 768px) {
    margin: 0px 8px 8px 0px;
    max-width: unset;
    width: 100%;
  }
`;

export const SelectDayRoom = ({ data, selectedDate, clearSelectDate }) => {
  const history = useHistory();
  const cxtLang = useContext(ContextLanguage);

  const handleSelectDay = day => {
    if (day === selectedDate) {
      clearSelectDate();
      history.push(`/app`);
    } else {
      history.push(`/app?date=${day}`);
    }
  };

  const formatDate = date => {
    if (!date) return new Date();

    const dateSplit = date?.split('/')?.map(item => Number(item));

    return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
  };

  const getTimeZone = language => {
    switch (language) {
      case 'pt':
        return ptBR;
      case 'en':
        return enUS;
      case 'es':
        return es;
    }
  };

  return (
    <Container>
      <Text type="h" finality="title" color="black" mobile={`margin-bottom: 16px;`}>
        {cxtLang.lang === 'pt' && 'SELECIONE O DIA DO EVENTO'}
        {cxtLang.lang === 'en' && 'SELECT THE DAY OF THE EVENT'}
        {cxtLang.lang === 'es' && 'SELECCIONA EL DÍA DEL EVENTO'}
      </Text>

      <ContentDays>
        {data.map((day, key) => {
          const dateSplit = day?.split('/').map(item => Number(item));

          return (
            <ButtonDay
              active={day === selectedDate}
              key={key}
              color="tertiary"
              onClick={() => handleSelectDay(day)}>
              {dbToDate(dateToDb(day), 'dd ')}
              DE
              {format(formatDate(day), ' MMMM', {
                locale: getTimeZone(cxtLang.lang),
              }).toLocaleUpperCase()}
            </ButtonDay>
          );
        })}
      </ContentDays>
    </Container>
  );
};
