import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { FiArrowRight } from 'react-icons/fi';
import { hooks } from '@ederzadravec/jade-ui';

import * as S from './style';

import AvatarImg from '../../assets/images/avatarImage.jpg';
import { Text } from '../Text';
import { Space } from '../Space/Space';
import { dbToDate } from 'utils/date';

import { useService } from 'service';
import { ContextLanguage } from 'contexts';

const validations = {
  content: [[value => !value, 'Deve ser preenchido']],
};

export const ListWall = () => {
  const [form, onChange] = hooks.useForm({ validations });
  const [dataWalls, { loading }] = useService('get', `/wall/walls-publics`, {}, true);
  const [, { loading: loadingSendWall }, sendWall] = useService('post', `/wall`, {}, false);

  const cxtLang = useContext(ContextLanguage);

  const handleOnSubmit = async () => {
    if (!form.getValue('content')) {
      return;
    }

    const data = {
      content: form.getValue('content'),
    };

    const result = await sendWall(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: `${
          (cxtLang.lang === 'pt' && 'Sua publicação foi enviada para moderação') ||
          (cxtLang.lang === 'en' && 'Your post has been sent for moderation') ||
          (cxtLang.lang === 'es' && 'Tu publicación ha sido enviada para moderación')
        }`,
        confirmButtonText: 'Ok',
      });
      form.clear();
    }

    if (result.error) {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'content',
        size: { md: 12 },
        type: S.CustomTextArea,
        props: schema => ({
          placeholder: `${
            (cxtLang.lang === 'pt' && 'Mensagem') ||
            (cxtLang.lang === 'en' && 'Message') ||
            (cxtLang.lang === 'es' && 'Mensaje')
          }`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <S.Container>
      <Text finality="title" type="h" color="black">
        {cxtLang.lang === 'pt' && 'PUBLICAÇÕES'}
        {cxtLang.lang === 'en' && 'FEED'}
        {cxtLang.lang === 'es' && 'PUBLICACIONES'}
      </Text>

      <Text size="18" color="black">
        {cxtLang.lang === 'pt' && 'Deixe a sua mensagem no nosso mural!'}
        {cxtLang.lang === 'en' && 'Leave your message on our wall!'}
        {cxtLang.lang === 'es' && '¡Deja tu mensaje en nuestro muro!'}
      </Text>

      <S.ContentWalls direction="column" show={loading}>
        {dataWalls?.success?.walls?.data.map(wall => (
          <S.Message key={wall._id}>
            <S.HeaderMessage>
              <S.ContentAvatar>
                {wall.viewer && <S.Avatar src={wall.viewer.photo} />}
                {wall.user && <S.Avatar src={AvatarImg} />}

                {wall.viewer.isDetach && <S.IconTrophy />}
              </S.ContentAvatar>

              <S.ContentInfo>
                <S.BodyContentInfo>
                  <Text size="16" type="span" color="black" bold="500">
                    {wall.user && wall.user.name + ' ADM'}
                    {wall.viewer && wall.viewer.name}
                  </Text>

                  <Space ml={1} inline />

                  <Text size="16" type="span" color="grey">
                    {dbToDate(wall.createdAt, 'HH:mm')}
                  </Text>
                </S.BodyContentInfo>

                <S.TextMessage type="p" size="14">
                  {wall.content}
                </S.TextMessage>
              </S.ContentInfo>
            </S.HeaderMessage>
          </S.Message>
        ))}
      </S.ContentWalls>

      <S.FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <S.Button
          loading={loadingSendWall}
          color="primary"
          padding={12}
          width={10}
          onClick={() => handleOnSubmit()}>
          <FiArrowRight color="#333" />
        </S.Button>
      </S.FormGrid>
    </S.Container>
  );
};
