import React from 'react'
import styled from 'styled-components';

import { Text } from 'components';
import { useService } from 'service';
import { dbToDate } from 'utils/date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

const ContainerHtml = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px;
`;

export const ThisHeader = () => {
  const [dataHeader, { loading }, fetchHeader] = useService(
    'get',
    `/header/60a5c413e3d43d1758697905`
  );

  const header = dataHeader?.success?.header;

  return (
    <Container>
      <Text size="18" bold="500">
        Titulo:
        <Text size="18"> {header?.title.pt}</Text>
      </Text>

      <Text size="18" bold="500">
        Sub-Titulo:
        <Text size="18"> {header?.subTitle.pt}</Text>
      </Text>

      <Text size="18" bold="500">
        Resumo:
      </Text>
      <ContainerHtml>
        <Text size="18">{header?.resume.pt}</Text>
      </ContainerHtml>

      <Text size="18" bold="500">
        Conteudo:
      </Text>
      <ContainerHtml dangerouslySetInnerHTML={{ __html: header?.content.pt }} />

      <Text size="18" bold="500">
        Data Inicio:
        <Text size="18"> {header?.dateStart}</Text>
      </Text>

      <Text size="18" bold="500">
        Data Fim:
        <Text size="18"> {header?.dateFinish}</Text>
      </Text>

      <Text size="18" bold="500">
        Imagem:
      </Text>
      <img width="400" src={header?.image} />
    </Container>
  );
};
