import React, { useContext } from 'react';
import styled from 'styled-components';

import { Box, Text } from 'components';
import { Room } from 'contexts';

import { Form } from './Form';
import { List } from './List';
import { ContextLanguage } from 'contexts';

const Container = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const Question = () => {
  const { question } = React.useContext(Room.Context);

  const isVisible = question?.questionTopic?.isVisible || false;
  const isVisibleForm = question?.questionTopic?.isVisibleForm || false;

  const cxtLang = useContext(ContextLanguage);

  if (!isVisible) return null;

  return (
    <Container>
      <Box>
        <Text size="24" type="h" color="primary" bold="500">
          {cxtLang.lang === 'pt' && `Deixe aqui a sua mensagem`}
          {cxtLang.lang === 'en' && `Leave your message here`}
          {cxtLang.lang === 'es' && `Ingrese su mensaje aquí`}
        </Text>

        {/* <Text size="24" type="h" color="primary" bold="500">
          {cxtLang.lang === 'pt' &&
            `Digite aqui sua pergunra: ${question?.questionTopic?.name[cxtLang.lang]}`}
          {cxtLang.lang === 'en' &&
            `Enter your question here:  ${question?.questionTopic?.name[cxtLang.lang]}`}
          {cxtLang.lang === 'es' &&
            `Ingrese su pregunta aquí: ${question?.questionTopic?.name[cxtLang.lang]}`}
        </Text> //original */}

        <List />

        {isVisibleForm && (
          <>
            <Text size="24" type="h" color="primary" bold="500">
              {cxtLang.lang === 'pt' && 'Deixe a sua resposta'}
              {cxtLang.lang === 'en' && 'Leave your answer'}
              {cxtLang.lang === 'es' && 'Deja tu respuesta'}
            </Text>
            <Form />
          </>
        )}
      </Box>
    </Container>
  );
};
