import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.palette.grey.light};
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;

  ${({ used, theme, block }) =>
    used
      ? css`
          background-color: ${theme.palette.primary.light};
          cursor: not-allowed;
        `
      : block &&
        css`
          &:hover {
            cursor: not-allowed;
            background-color: ${({ theme }) => theme.palette.grey.light};
          }
        `}
`;
