export const formLogin = {
    es: {
      title: 'Sea bienvenido',
      labelFieldEmail: 'E-mail',
      labelFieldPassword: 'Contraseña',
      labelButton: 'Entrar',
      textForgetPassword: 'Olvidaste tu contraseña?',
      uriTextForgetPassword: 'aqui',
      help: 'Necesita ayuda?',
      /*   textIsRegistered: 'Si aún no se ha registrado, haga clic en ',
      uritextIsRegistered: 'aqui', */
    },
    en: {
      title: 'Welcome',
      labelFieldEmail: 'E-mail',
      labelFieldPassword: 'Password',
      labelButton: 'Login',
      textForgetPassword: 'Forgot your password? ',
      uriTextForgetPassword: 'here',
      help: 'need help',
      /*   textIsRegistered: 'If you have not yet registered, click ',
      uritextIsRegistered: 'here', */
    },
    pt: {
      title: 'Seja bem-vindo(a)',
      labelFieldEmail: 'E-mail',
      labelFieldPassword: 'Senha',
      labelButton: 'Entrar',
      textForgetPassword: 'Esqueceu sua senha? ',
      uriTextForgetPassword: 'aqui',
      help: 'Precisa de ajuda',
      /*  textIsRegistered: 'Se você ainda não se cadastrou, Clique ',
      uritextIsRegistered: 'aqui', */
    },
  };