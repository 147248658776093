import React, { useState } from 'react';
import { Grid, Button } from '@ederzadravec/jade-ui';

import LogoBrand from 'assets/images/brand.svg';
import ImageLeft from 'assets/images/image_left.svg';

import { Welcome } from './Welcome';
import { FormLogin } from './FormLogin';
import { FormRecover } from './FormRecover';
import { FormRegister } from './FormRegister';
import { FormRegisterConfirm } from './FormRegisterConfirm';

import { Space, Modal, Text } from 'components';

import { Flags } from './Flags';

import { Container, ContentForm, ImageLogo, Image, Footer, ContentModal } from './style';

export default () => {
  const [action, setAction] = useState('welcome');
  const [showModal, setShowModal] = useState(true);

  const render = () => {
    switch (action) {
      case 'welcome':
        return <Welcome setAction={setAction} />;
      case 'recover':
        return <FormRecover setAction={setAction} />;
      case 'login':
        return <FormLogin setAction={setAction} />;
      case 'register':
        return <FormRegister setAction={setAction} />;
      case 'registerConfirm':
        return <FormRegisterConfirm setAction={setAction} />;
    }
  };

  return (
    <>
      <Container>
        <Flags />

        <Grid size={{ md: 6, xs: 12 }}>
          <Image src={ImageLeft} alt="Logo" />
        </Grid>

        <Grid size={{ md: 6, xs: 12 }}>
          <ContentForm>
            <ImageLogo src={LogoBrand} alt="Logo" />

            <>{render()}</>
          </ContentForm>
        </Grid>

        <Footer center bold="500">
          Desenvolvido por BTO+ Comunicação e Deploy. Future Agency
        </Footer>
      </Container>

      {showModal && (
        <Modal
          size={{ sm: 12, md: 6, xs: 12 }}
          onClose={() => {
            setShowModal(false);
          }}>
          <ContentModal>
            <Text color="primary" type="h" size="24" bold="500">
              OLÁ
            </Text>

            <Space mt={1} />

            <Text color="dark" type="p" size="16">
              Você está na plataforma da Convenção Global de Vendas e Serviços 2024. <br />
              Se você já é um participante inscrito no evento, você precisará criar uma senha para
              prosseguir. <br /> <br />
              <Text color="dark" type="p" size="18">
                Veja abaixo o passo-a-passo:
              </Text>
              <Space mt={-1} />
              <Text color="dark" type="p" size="14" style={{ lineHeight: ' 24px' }}>
                <br /> 1. Acesse o link www.convencao2024.totvs.com;
                <br /> 2. Clique no botão "CRIAR SENHA";
                <br /> 3. Preencha o campo com o seu email corporativo;
                <br /> 4. Acesse o link que foi enviado no seu email, caso não encontre o link
                aguarde alguns minutos e verifique também a sua caixa de spam;
                <br /> 5. Crie sua senha e aproveite o evento! Caso não tenha conseguido acessar,
                entre em contato com a gente pelo{' '}
                <Text
                  color="dark"
                  bold="bold"
                  type="link"
                  size="14"
                  onClick={() => {
                    window.open('https://wa.me/5511934165295', '_blank');
                  }}>
                  WhatsApp
                </Text>
              </Text>
            </Text>

            <Space mt={2} />

            <Button onClick={() => setShowModal(false)} variant="outline">
              OK
            </Button>
          </ContentModal>
        </Modal>
      )}
    </>
  );
};
