import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Box, Text } from 'components';

import { ModalDetail } from './ModalDetail';
import { useService } from 'service';
import { CardRoom } from './CardRoom';
import { dbToDate, newDate } from 'utils/date';

const Container = styled.div`
  background-size: cover;
  width: 100%;
  margin-top: 24px;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 768px) {
    padding: 4px;
    flex-wrap: wrap;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const GroupRooms = ({ date, ...props }) => {
  const [idModalShow, setIdModalShow] = useState('');
  const [dataRooms, { loading }, fetchRoom] = useService(
    'get',
    `app/room/?date=${date.date || ''}&limit=100`,
    {},
    true,
    [date]
  );

  const dateRooms = dataRooms?.success?.rooms?.data || [];

  const formatDate = date => {
    if (!date) return new Date();

    const dateSplit = dbToDate(date)
      ?.split('/')
      ?.map(item => Number(item));

    return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
  };

  return (
    <Box>
      <Text type="h" finality="title" color="secondary">
        {dbToDate(dateRooms[0]?.openAt, "dd' de")}
        {format(formatDate(dateRooms[0]?.openAt), ' MMMM', {
          locale: ptBR,
        }).toLocaleUpperCase()}
        {dbToDate(dateRooms[0]?.openAt, "' | ' HH':'mm")}
        {dbToDate(dateRooms[dateRooms.length - 1]?.closeAt, "' - ' HH':'mm")}
      </Text>

      <Container {...props}>
        <Loader show={loading}>
          <Content lengthRooms={dateRooms.length}>
            {dateRooms.map((data, key) => (
              <CardRoom key={key} data={data} setIdModalShow={setIdModalShow} />
            ))}
          </Content>
        </Loader>
      </Container>

      {idModalShow && <ModalDetail onClose={() => setIdModalShow('')} dataRoom={idModalShow} />}
    </Box>
  );
};
