export const wellcome = {
  es: {
    title: 'Sea bienvenido',
    descripition:
      'Atención: Haz clic en CREAR CONTRASEÑA para acceder a la plataforma.',
    labelButtonLogin: 'LOGIN',
    labelButtonRegister: 'CREAR CONTRASEÑA',
  },
  en: {
    title: 'Welcome',
    descripition:
      'Attention: Click LOGIN and enter the password was sent to your email.',
    labelButtonLogin: 'LOGIN',
    labelButtonRegister: 'REGISTER',
  },
  pt: {
    title: 'Seja bem-vindo(a)',
    descripition: 'Atenção: Clique em CRIAR SENHA para acessar a plataforma.',
    labelButtonLogin: 'LOGIN',
    labelButtonRegister: 'CRIAR SENHA',
  },
};
