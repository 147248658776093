import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FiCalendar, FiSettings, FiLogOut, FiStar } from 'react-icons/fi';
import { FaMedal, FaStar, FaFileAlt } from 'react-icons/fa';
import { GrTrophy } from 'react-icons/gr';
import { MdDashboard } from 'react-icons/md';
import Swal from 'sweetalert2';
import TagManager from 'react-gtm-module';

import { isXS } from 'utils/screens';
import ImageLeft from 'assets/images/bg_login.jpeg';

import { MenuDesktop } from './MenuDesktop';
import { MenuMobile } from './MenuMobile';
import { Text } from '../Text';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;

  background-image: url(${ImageLeft});
  background-size: cover;
  background-attachment: fixed;

  @media (max-width: 768px) {
    min-height: unset;
    position: relative;
  }
`;

const Content = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: inherit;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SideBar = styled.div`
  transition: all ease 0.3s;
  width: ${({ active }) => (active ? 300 : 100)}px;
  padding: 50px 0;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.dark.light};
  display: flex;
  flex-direction: column;
  background: #eeeeee;

  position: relative;

  @media (max-width: 768px) {
    transition: 0s;
    width: ${({ active }) => (active ? 300 : 0)}px;
    ${({ active }) => (active ? `position: absolute; width: 100%; top: 0; z-index: 1;` : ``)};
  }
`;

const ContentSidebar = styled.div`
  width: inherit;

  ${({ scroling }) =>
    scroling
      ? `
  position: fixed;
  top: 30px;
  `
      : `
  position: relative;
  `}

  @media (max-width: 768px) {
    position: relative;
    width: unset;
    top: unset;
  }
`;

const Item = styled.div`
  height: 40px;
  width: 90%;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`;

const Active = styled.span`
  content: ' ';
  height: 55px;
  width: 8px;
  //background: ${({ theme, active }) => (active ? 'theme.palette.tertiary.main' : '')};
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
`;

const MenuIcon = styled.span`
  width: 70px;
  font-size: 24px;
  color: ${({ theme, active }) => (active ? theme.palette.tertiary.main : '')};
`;

const Label = styled(Text)`
  width: ${({ show }) => (show ? 'auto' : '0px')};
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  font-size: 15px;
  color: ${({ theme, active }) => (active ? theme.palette.tertiary.main : theme.palette.dark.main)};
  font-weight: ${({ theme, active }) => (active ? 'bold' : '400')};
`;

const Body = styled.div`
  transition: all ease 0.3s;
  //background-color: ${({ theme }) => theme.palette.white.main};
  border-top: 1px solid ${({ theme }) => theme.palette.dark.light};
  flex: 1;
  display: flex;

  /* background: -moz-linear-gradient(120deg, rgba(11, 11, 11, 1) 0%, rgba(201, 10, 31, 1) 100%);
  background: -webkit-linear-gradient(120deg, rgba(11, 11, 11, 1) 0%, rgba(201, 10, 31, 1) 100%);
  background: -o-linear-gradient(120deg, rgba(11, 11, 11, 1) 0%, rgba(201, 10, 31, 1) 100%);
  background: -ms-linear-gradient(120deg, rgba(11, 11, 11, 1) 0%, rgba(201, 10, 31, 1) 100%);
  background: linear-gradient(330deg, rgba(11, 11, 11, 1) 0%, rgba(201, 10, 31, 1) 100%); */

  padding: 32px;

  @media (max-width: 768px) {
    flex: 1;
    position: relative;
    padding: 16px 8px;
    z-index: 0;
  }
`;

const CloseMenuMobile = styled.span`
  padding: 0 20px;
  color: ${({theme})=> theme.palette.black.main};
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 5%;
  cursor: pointer;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 30px;
  padding: 20px;

  ${({ active }) => (active ? 'display: block;' : 'display: none;')};
  display: none;
`;

const LabelFooter = styled(Text)`
  color: ${({ theme }) => theme.palette.dark.main};
`;

const LinkFooter = styled.a`
  color: ${({ theme }) => theme.palette.dark.main};
`;

const tagManagerArgs = {
  gtmId: 'GTM-MR3PHNW',
};

TagManager.initialize(tagManagerArgs);

export const Layout = ({ children }) => {
  const [sideActive, setSideActive] = React.useState(!isXS());
  const [scroling, setScroling] = React.useState(false);
  const [, setRefresh] = React.useState(new Date());

  const handleScroll = () => {
    if (window.pageYOffset > 160) {
      setScroling(true);
    } else {
      setScroling(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  const history = useHistory();

  const handleOnPush = route => {
    if (route === '/logout') {
      Swal.fire({
        title: `${cxtLang.texts[cxtLang.lang].menuTextConfirmExit}`,
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonColor: '#37B6C4',
        cancelButtonColor: '#F51B0F',
        confirmButtonText: `${cxtLang.texts[cxtLang.lang].menuConfirmButton}`,
        cancelButtonText: `${cxtLang.texts[cxtLang.lang].menuCancelButton}`,
        backdrop: `rgba(0,0,0,0.4)`,
      }).then(result => {
        if (!result.isConfirmed) {
          history.push('/app');
        }
        if (result.isConfirmed) {
          history.push(route);
        }
      });
    } else {
      history.push(route);
    }

    setRefresh(new Date());

    if (isXS()) setSideActive(false);
  };

  const getActive = path => history.location.pathname === path;

  const cxtLang = useContext(ContextLanguage);

  const dataMenu = [
    {
      name: `${cxtLang.texts[cxtLang.lang].menuUriHomePage}`,
      path: '/app',
      icon: MdDashboard,
      active: getActive,
    },

    // {
    //   name: `${cxtLang.texts[cxtLang.lang].menuScheduleOne}`,
    //   path: '/app/schedule',
    //   icon: FiCalendar,
    //   active: getActive,
    // },

    {
      name: `${cxtLang.texts[cxtLang.lang].menuScheduleTwo}`,
      path: '/app/schedule2',
      icon: FiCalendar,
      active: getActive,
    },

    {
      name: `${cxtLang.texts[cxtLang.lang].menuUriSpeakers}`,
      path: '/app/best-sellers',
      icon: GrTrophy,
      active: getActive,
    },

    // {
    //   name: 'Vouchers',
    //   path: '/app/award',
    //   icon: FiAward,
    //   active: getActive,
    // },

    // {
    //   name: `${cxtLang.texts[cxtLang.lang].menuUriPosters}`,
    //   path: '/app/posters',
    //   icon: FaFileAlt,
    //   active: getActive,
    // },

    // {
    //   name: `${cxtLang.texts[cxtLang.lang].menuUriSponsors}`,
    //   path: '/app/sponsors',
    //   icon: FaStar,
    //   active: getActive,
    // },

    {
      name: `${cxtLang.texts[cxtLang.lang].menuUriSettings}`,
      path: '/app/settings',
      icon: FiSettings,
      active: getActive,
    },

    {
      name: `${cxtLang.texts[cxtLang.lang].menuUriExit}`,
      path: '/logout',
      icon: FiLogOut,
      active: getActive,
    },
  ];

  return (
    <Container>
      <MenuDesktop
        setSideActive={() => {
          setSideActive(!sideActive);
        }}
      />
      <MenuMobile
        setSideActive={() => {
          setSideActive(!sideActive);
        }}
      />
      <Content>
        <SideBar active={sideActive}>
          <ContentSidebar scroling={scroling}>
            {sideActive && (
              <CloseMenuMobile
                onClick={() => {
                  setSideActive(!sideActive);
                }}>
                {isXS() ? 'X' : ''}
              </CloseMenuMobile>
            )}

            {dataMenu.map((item, key) => (
              <Item key={key} onClick={() => handleOnPush(item.path)}>
                <Active active={item.active(item.path)} />

                <MenuIcon as={item.icon} active={item.active(item.path)} />

                <Label show={sideActive} active={item.active(item.path)}>
                  {item.name}{' '}
                </Label>
              </Item>
            ))}
          </ContentSidebar>

          <Footer active={sideActive}>
            <LabelFooter size="16">
              Desenvolvido por <br />
              <LinkFooter href="https://btomais.com.br" target="_blank">
                BTO+ Comunicação
              </LinkFooter>{' '}
              e
              <br />
              <LinkFooter href="https://deploy.ag" target="_blank">
                Deploy. Future Agency
              </LinkFooter>
            </LabelFooter>
          </Footer>
        </SideBar>

        <Body>{children}</Body>
      </Content>
    </Container>
  );
};
