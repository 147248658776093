import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { Text, Space } from 'components';
import LogoBrand from 'assets/images/brand.svg';
import BGPage from 'assets/images/bg_login.jpeg';

import { ContextLanguage } from 'contexts';
import { registerFull } from './Texts/RegisterFull';

import { Form } from './Form';

import { Flags } from '../login/Flags';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${BGPage});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  min-width: 65%;
  background: #ffffff;
  border-radius: 14px;
  padding: 18px 20px;
`;

const ImageLogo = styled.img`
  display: block;
  width: 200px;
  margin: 16px 0;
`;

export default () => {
  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(registerFull);
  }, []);

  return (
    <Container>
      <Flags />

      <Content>
        <ImageLogo src={LogoBrand} alt="Logo" />

        <Text type="h" color="primary" bold="500">
          {cxtLang.texts[cxtLang.lang].titleForm}
        </Text>

        <Form />
      </Content>
    </Container>
  );
};
