export const textsFormRegisterConfirm = {
  es: {
    title: 'Completa el campo a continuación para recibir un correo electrónico con la creación de tu contraseña.',
    labelFieldEmail: 'E-mail',
    labelButton: 'Enviar',
    uriBackToLogin: 'Volver a iniciar sesión',
    fieldEmpty: 'Campo Obligatorio',
    textInvalidEmail: 'Email inválido. No se puede continuar con el registro.',
    successMessage:
      '¡Revise su correo electrónico para ver el enlace para completar su registro en nuestro sitio web!',
  },
  en: {
    title: 'Confirm Registration',
    labelFieldEmail: 'E-mail',
    labelButton: 'Send',
    uriBackToLogin: 'Back to login',
    fieldEmpty: 'Required field',
    textInvalidEmail: 'Invalid email. Unable to proceed with registration.',
    successMessage: 'Check your email for the link to complete your registration on our website.',
  },
  pt: {
    title: 'Preencha o campo abaixo para receber um email de criação da sua senha.',
    labelFieldEmail: 'E-mail',
    labelButton: 'Enviar',
    uriBackToLogin: 'Volte ao login',
    fieldEmpty: 'Campo Obrigatório',
    textInvalidEmail: 'Não encontramos o seu email em nosso cadastro, verifique o email digitado e tente novamente.',
    successMessage: 'Confira em seu e-mail o link para concluir o seu acesso à plataforma, caso não visualize o email, cheque a sua caixa de SPAM.',
  },
};
